// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-about-us-js": () => import("/opt/build/repo/src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-where-to-buy-js": () => import("/opt/build/repo/src/templates/where-to-buy.js" /* webpackChunkName: "component---src-templates-where-to-buy-js" */),
  "component---src-templates-search-js": () => import("/opt/build/repo/src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-subsection-js": () => import("/opt/build/repo/src/templates/subsection.js" /* webpackChunkName: "component---src-templates-subsection-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

